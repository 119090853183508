import React, {
    useContext,
    useEffect,
    useState 
} from 'react';
import { doc, updateDoc,onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import { useCookies } from "react-cookie";
import * as LoadingSpinner from "react-spinners-kit";
import SearchBar from "../components/SearchBar";

const Home = (props) => {
    document.title = "Drake Forum • Home"

    const { currentUser } = useContext(AuthContext);
    const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null);
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    const [userData,setUserData] = useState('');
    const [status,setStatus] = useState(null);
    const [searchText,setSearchText] = useState(props.SearchText);
    // const [searchKeyword, setSearchKeyword] = useState("");
    
    useEffect(() => {
        const interval = setInterval(() => {
        var current = new Date();
        var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
        var CurrentDate = current.toLocaleDateString();
        setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const ShowLoginAlert = async() => {
        swal({
            title: "LOGIN",
            text: "Please Login To Create A Category!"
        })
    }

    const SendCategoryDetails = async(val) => {
        props.ChangeCategory(val);
        navigate("/Category/"+val.id+"/"+val.Subject.replaceAll(' ', '-'))
    }

    useEffect(() => {
        const GetData = async () => {
            if(currentUser){
                const unsub = onSnapshot(doc(db, "Users", currentUser.uid), (doc) => {
                    setUserData(doc.data());
                    setStatus(doc.data().SetStatusDone);
                    setCookie("user", doc.data(), { path: "/" });
                });
                return () => {
                unsub();
                };
            }
        }
        GetData()
    }, [])

    const UpdateStatus = async() => {
        if(currentUser){
            if(status === false) {
                if(props.IPAddress !== ''){
                    if(combinedCurrentDateTime !== null){
                        await updateDoc(doc(db, "Users", currentUser.uid), {
                            Status: "Active Now",
                            Device_IP: props.IPAddress,
                            LoginTime: combinedCurrentDateTime,
                            SetStatusDone: true,
                        });
                        setStatus(true)
                    }
                }
            }
        }
    }
    UpdateStatus()

    useEffect(() => {
        props.SendUserData(userData)
    }, [userData])

    useEffect(() => {
        props.SendSearchText(searchText)
    }, [searchText])

    // useEffect(() => {
    //     props.GetSearchKeyword(searchKeyword);
    // }, [searchKeyword])

    // useEffect(() => {
    //     console.log(props.SearchedQuestionsData)
    // }, [props.SearchedQuestionsData])
    
    return (
        <div className='HomeBody'>
            <div className="app">
                <div className="header">
                    <div className="header-menu top-menu">
                        <a className="menu-link is-active" href="/">Home</a>
                        <a className="menu-link notify" href="/">Contact</a>
                        <a className="menu-link" href="/">Login</a>
                        <a className="menu-link notify" href="/">Register</a>

                        <div className="header-menu">
                            <a className="menu-link" href="/">Login</a>
                        </div>

                    </div>
                    <SearchBar SearchText={props.SearchText} SearchBarText={(value) => setSearchText(value)} GetSearchKeyword={(value) => props.GetSearchKeyword(value)}/>
                    <div className="header-profile">
                        {currentUser
                            ?
                                <img className="profile-img cursor-pointer" src={currentUser.photoURL} alt="" onClick={() => navigate("/Profile")}/>
                            :
                                <div className="header-menu cursor-pointer text-slate-300" onClick={() => navigate("/Login")}>
                                    Login
                                </div>
                        }
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="main-header">
                            <div className="header-menu">
                                <a className="main-header-link cursor-pointer is-active" onClick={() => navigate("/")}>Home</a>
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/Contact")}>Contact</a>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="content-wrapper-header">
                                <div className="content-wrapper-context">
                                    <h3 className="img-content">DRAKE FORUM</h3>
                                    <div className="content-text">This is a free forum created for users to ask queries and other users can solve the queries</div>
                                    {currentUser
                                        ?
                                            <button className="content-button cursor-pointer" onClick={() => navigate("/Create")}>Create Category</button>
                                        :
                                            <button className="content-button" onClick={ShowLoginAlert}>Create Category</button>
                                    }
                                </div>
                            </div>
                            <div className="content-section">
                                <div className="content-section-title">CATEGORIES</div>
                                {props.SendCategoryLoading
                                    ?
                                        <>
                                            <div className="apps-card" style={{justifyContent:"center",alignItems: "center",textAlign: "center",marginTop: "20px"}}>
                                                <LoadingSpinner.MetroSpinner size={100} color="#ff0058" loading={props.SendCategoryLoading} />
                                            </div>
                                        </>
                                    :
                                        <>
                                            <div className="apps-card">
                                                {props.Category.length === 0
                                                    ?
                                                        <>
                                                            <div className="app-card-ans" style={{justifyContent: 'center',alignItems: 'center',textAlign: 'center'}}>
                                                                <h1>No Categories Created</h1>
                                                            </div>
                                                        </>
                                                    :
                                                        props.Category.map(cat => (
                                                        <>
                                                            <div className="app-card" id={cat.id} key={cat.id} onClick={() => SendCategoryDetails(cat)}>
                                                                    <span onClick={() => SendCategoryDetails(cat)}>
                                                                        {cat.Subject}
                                                                    </span>
                                                                    <div className="app-card__subtext" onClick={() => SendCategoryDetails(cat)}>This category contains queries related to {cat.Subject}</div>
                                                            </div>
                                                        </>
                                                ))}
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
        </div>
    )
}

export default Home
