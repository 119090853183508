import React, { useContext,useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import * as LoadingSpinner from "react-spinners-kit";
import SearchBar from "../components/SearchBar";

const Category = (props) => {
    const { ID } = useParams()
    const { Name } = useParams()
    document.title = `Drake Forum • ${Name.replaceAll('-',' ')}`;
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [searchText,setSearchText] = useState(props.SearchText);
    const [searchKeyword, setSearchKeyword] = useState("");
    
    const ShowLoginAlert = async() => {
        swal({
            title: "LOGIN",
            text: "Please Login To Ask Questions!"
        })
    }
    
    useEffect(() => {
        props.SendCategoryID(ID)
    }, [])

    const NavigateToQuestionsPage = async(value) => {
        navigate("/Questions/"+ID+"/"+value.id+"/"+value.Question.replaceAll(' ', '-'))
    }

  
    useEffect(() => {
        props.SendSearchText(searchText)
    }, [searchText])

    
    // useEffect(() => {
    //     props.GetSearchKeyword(searchKeyword);
    // }, [searchKeyword])
    
    return (
        <div className='HomeBody'>
            <div className="app">
                <div className="header">
                    <SearchBar SearchText={props.SearchText} SearchBarText={(value) => setSearchText(value)} GetSearchKeyword={(value) => props.GetSearchKeyword(value)}/>
                    <div className="header-profile">
                        {currentUser
                            ? 
                                <img className="profile-img cursor-pointer" src={currentUser.photoURL} alt="" onClick={() => navigate("/Profile")}/>
                            :
                                <div className="header-menu cursor-pointer text-slate-300" onClick={() => navigate("/Login")}>
                                    Login
                                </div>
                        }
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="main-header">
                            <div className="header-menu">
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/")}>Home</a>
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/Contact")}>Contact</a>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="content-wrapper-header">
                                <div className="content-wrapper-context">
                                    <h3 className="img-content">{Name.replaceAll('-',' ')}</h3>
                                    <div className="content-text">This category contains all queries and answers related to {Name.replaceAll('-',' ')}</div>
                                    {currentUser
                                        ?
                                            <button className="content-button cursor-pointer" onClick={() => navigate("/Ask/"+ID+"/"+Name)}>ASK QUESTIONS</button>
                                        :
                                            <button className="content-button" onClick={ShowLoginAlert}>ASK QUESTIONS</button>
                                    }
                                </div>
                            </div>
                            <div className="content-section">
                                <div className="content-section-title">QUESTIONS</div>
                                {props.SendQuestionLoading
                                    ?
                                        <>
                                            <div className="apps-card" style={{justifyContent:"center",alignItems: "center",textAlign: "center",marginTop: "20px"}}>
                                                <LoadingSpinner.MetroSpinner size={100} color="#ff0058" loading={props.SendQuestionLoading} />
                                            </div>
                                        </>
                                    :
                                        <>
                                            <div className="apps-card">
                                                {props.DataOfQuestion === "NO DATA"
                                                    ?
                                                        <div className="app-card" style={{justifyContent: 'center',alignItems: 'center',textAlign: 'center'}}>
                                                            <h1>No Questions In This Category</h1>
                                                        </div>
                                                    :
                                                        props.DataOfQuestion.sort((a, b) => b.date - a.date).map(quest => (
                                                            <>
                                                                <div className="app-card" id={quest.id} key={quest.id} onClick={() => NavigateToQuestionsPage(quest)}>
                                                                    <span>
                                                                        {quest.Question}
                                                                    </span>
                                                                    {quest.QuestionScreenShot
                                                                        ?
                                                                            <>
                                                                                <div className="app-card-img">
                                                                                    <img src={quest.QuestionScreenShot} alt="" />
                                                                                </div>
                                                                            </>
                                                                        :
                                                                            null
                                                                    }
                                                                    <div className="app-card__subtext">This question is asked by {quest.UserName}</div>
                                                                </div>
                                                            </>
                                                        ))
                                                }
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
        </div>
    )
}

export default Category
