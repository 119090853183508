import React, { useContext,useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import * as LoadingSpinner from "react-spinners-kit";
import SearchBar from "../components/SearchBar";

const Questions = (props) => {
    const { CatID } = useParams();
    const { QuestionID } = useParams();
    const { Question } = useParams();
    document.title = `Drake Forum • ${Question.replaceAll('-',' ')}`;
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [searchText,setSearchText] = useState(props.SearchText);
    const [searchKeyword, setSearchKeyword] = useState("");
    
    const ShowLoginAlert = async() => {
        swal({
            title: "LOGIN",
            text: "Please Login To Answer The Question!"
        })
    }
    
    useEffect(() => {
        props.SendQuestionID(QuestionID);
    }, [])
    
    useEffect(() => {
        if(props.DataOfQuestion.length === 0){
            props.SendCategoryID(CatID)
        }
    }, [])

  
    useEffect(() => {
        props.SendSearchText(searchText)
    }, [searchText])

    
    // useEffect(() => {
    //     props.GetSearchKeyword(searchKeyword);
    // }, [searchKeyword])
    
    return (
        <div className='HomeBody'>
            <div className="app">
                <div className="header">
                    <SearchBar SearchText={props.SearchText} SearchBarText={(value) => setSearchText(value)} GetSearchKeyword={(value) => props.GetSearchKeyword(value)}/>
                    <div className="header-profile">
                        {currentUser
                            ? 
                                <img className="profile-img cursor-pointer" src={currentUser.photoURL} alt="" onClick={() => navigate("/Profile")}/>
                            :
                                <div className="header-menu cursor-pointer text-slate-300" onClick={() => navigate("/Login")}>
                                    Login
                                </div>
                        }
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="main-header">
                            <div className="header-menu">
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/")}>Home</a>
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/Contact")}>Contact</a>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="content-wrapper-header">
                                <div className="content-wrapper-context">
                                    <h3 className="img-content">{Question.replaceAll("-"," ")}</h3>
                                    {props.DataOfAnswer === "NO DATA"
                                        ?
                                            null
                                        :
                                            props.DataOfQuestion.map(quest => (
                                                quest.QuestionScreenShot
                                                ?                                        
                                                    <>
                                                        <div className="app-card-img">
                                                            <img src={quest.QuestionScreenShot} alt="" />
                                                        </div>
                                                    </>
                                                :
                                                    null
                                    ))}
                                    {currentUser
                                        ?
                                            <button className="content-button cursor-pointer" onClick={() => navigate("/Reply/"+CatID+"/"+QuestionID+"/"+Question.replaceAll(" ","-"))} >ANSWER THE QUESTION</button>
                                        :
                                            <button className="content-button" onClick={ShowLoginAlert}>ANSWER THE QUESTION</button>
                                    }
                                </div>
                            </div>
                            <div className="content-section">
                                <div className="content-section-title">ANSWERS</div>
                                {props.SendAnswerLoading
                                    ?
                                        <>
                                            <div className="apps-card-answers" style={{justifyContent:"center",alignItems: "center",textAlign: "center",marginTop: "20px"}}>
                                                <LoadingSpinner.MetroSpinner size={100} color="#ff0058" loading={props.SendAnswerLoading} />
                                            </div>
                                        </>
                                    :
                                        <>
                                            <div className="apps-card-answers">
                                                {props.DataOfAnswer === "NO DATA"
                                                    ?
                                                        <div className="app-card-ans" style={{justifyContent: 'center',alignItems: 'center',textAlign: 'center'}}>
                                                            <h1>No Answers For This Question</h1>
                                                        </div>
                                                    :
                                                        props.DataOfAnswer.sort((a, b) => b.date - a.date).map(ans => (
                                                            <>
                                                                <div className="app-card-ans" id={ans.id} key={ans.id}>
                                                                    <span>
                                                                        {ans.Answer}
                                                                    </span>
                                                                    {ans.AnswerScreenShot
                                                                        ?
                                                                            <>
                                                                                <div className="app-card-img">
                                                                                    <img src={ans.AnswerScreenShot} alt="" />
                                                                                </div>
                                                                            </>
                                                                        :
                                                                            null
                                                                    }
                                                                    <div className="app-card__subtext font-extrabold">This question is answered by {ans.UserName}</div>
                                                                </div>
                                                            </>
                                                        ))
                                                }
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
        </div>
    )
}

export default Questions