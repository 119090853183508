/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { AuthContext } from "../context/AuthContext";

const Login = () => {
  document.title = "Drake Forum • Login"

  const { currentUser } = useContext(AuthContext);

  const [err, setErr] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const HandleSubmit = async (e) => {
        e.preventDefault()
        const EMail = e.target[0].value;
        const Password = e.target[1].value;
        try {
            setLoading(true);
            await signInWithEmailAndPassword(auth, EMail, Password);
            navigate("/")
        } catch (err) {
            setErr(true);
            setErrorCode(err.code);
            setErrorMessage(err.message);
            setLoading(false);
        }
    }

  if (!loading) {
    if (currentUser) {
      navigate("/")
    }
  }

  return (
    <>
      <body id="FormBody">
        <div className="login">
          <div className="login__content">
            <div className="login__img">
              <img src="https://raw.githubusercontent.com/bedimcode/responsive-login-signin-signup/b3c2eaa19d76624092bd606d28fbd616d539de92/assets/img/img-login.svg" alt="" />
            </div>

            <div className="login__forms">
              <form onSubmit={HandleSubmit} className="login__registre" id="login-in">
                <h1 className="login__title">SIGN IN</h1>

                <div className="login__box">
                  <i class='bx bx-user login__icon'></i>
                  <input required type="text" placeholder="E-MAIL" className="login__input" />
                </div>

                <div className="login__box">
                  <i class='bx bx-lock-alt login__icon'></i>
                  <input required type="password" placeholder="PASSWORD" className="login__input" />
                </div>
                {!loading && <a className="login__forgot cursor-pointer" onClick={() => navigate("/ResetPassword")}>Forgot password?</a>}
                {!loading && err && <span className="text-red-500 uppercase font-extrabold">Something went wrong</span>}<br />
                {!loading && err && <span className="text-red-500 uppercase font-extrabold">{errorCode}</span>}
                {/* {!loading && err && <span className="text-red-500 uppercase font-extrabold">{errorMessage}</span>} */}
                {loading && <button className="login__button cursor-pointer w-full" disabled="true">Signing In</button>}
                {!loading && <button className="login__button cursor-pointer w-full">Sign In</button>}

                {!loading &&
                  <>
                    <div>
                      <span className="login__account">Don't have an Account? </span>
                      <span className="login__signin cursor-pointer" id="sign-up" onClick={() => navigate("/Register")}>Sign Up</span>
                      <br />
                      <span className="login__signin cursor-pointer" id="sign-up" onClick={() => navigate("/")}>RETURN HOME</span>
                    </div>
                  </>
                }
              </form>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
export default Login