import React,{useState,useEffect,useContext} from 'react';
import Home from './pages/Home';
import Login from "./pages/Login";
import Register from "./pages/Register";
import Contact from "./pages/Contact";
import Profile from "./pages/Profile";
import UpdateUsername from "./pages/UpdateUsername";
import UpdateEMail from "./pages/UpdateEMail";
import UpdatePassword from "./pages/UpdatePassword";
import UpdateProfilePicture from "./pages/UpdateProfilePicture";
import DeleteAccount from "./pages/DeleteAccount";
import Category from "./pages/Category";
import Create from "./pages/Create";
import Ask from "./pages/Ask";
import Reply from "./pages/Reply";
import Questions from "./pages/Questions";
import Search from "./pages/Search";
import PageNotFound from './pages/PageNotFound';
import TopBarProgress from "react-topbar-progress-indicator";
import {
  BrowserRouter,
  useLocation,
  Route,
  Routes,
} from "react-router-dom";
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {AuthContext} from "./context/AuthContext";
import { doc,onSnapshot,query,collection,where } from "firebase/firestore";
import { db } from "./firebase";
import './App.css';

const CustomSwitch = ({ children }) => {
  const [progress, setProgress] = useState(false)
  const [prevLoc, setPrevLoc] = useState("")
  const location = useLocation()

  useEffect(() => {
     setPrevLoc(location.pathname)
     setProgress(true)
     if(location.pathname===prevLoc){
         setPrevLoc('')
     }
  }, [location])

  useEffect(() => {
     setProgress(false)
  }, [prevLoc])

  return (
     <>
        {progress && <TopBarProgress />}
        <Routes>{children}</Routes>
     </>
  )
}

function App() {
  const { currentUser } = useContext(AuthContext);
  const [userData,setUserData] = useState('');
  const [categories,setCategories] = useState([]);
  const [ip, setIP] = useState('');
  const [category,setCategory] = useState(null);
  const [catID,setCatID] = useState(null);
  const [questionID,setQuestionID] = useState(null);
  const [questionsData,setQuestionsData] = useState([]);
  const [answersData,setAnswersData] = useState([]);
  const [searchedQuestionsData, setSearchedQuestionsData] = useState([]);
  const [searchedAnswersData, setSearchedAnswersData] = useState([]);
  const [categoryLoading,setCategoryLoading] = useState(false);
  const [questionLoading,setQuestionLoading] = useState(false);
  const [answerLoading,setAnswerLoading] = useState(false);
  const [searchingText,setSearchingText] = useState("");
  // const [searchedKeyword,setSearchedKeyword] = useState("");
  // const navigate = useNavigate();

  useEffect(() => {
      setCategoryLoading(true)
      const unSub = onSnapshot(doc(db, "Topic", "Name"), (doc) => {
        setCategories(doc.data().categories);
        setCategoryLoading(false);
      });
      return () => {
        unSub();
      };
  }, []);

  TopBarProgress.config({
    barThickness:6,
    barColors: {
      "0": "#ff0058",
      "1": "#ff0058",
  }});

  useEffect(() => {
    const GetQuestionsData = async() => {
      setQuestionLoading(true)
      const q = query(collection(db, "Questions"), where("CatID", "==", catID));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if(querySnapshot.empty){
          setQuestionsData("NO DATA");
          setQuestionLoading(false)
        }else{
          let questionData = []
          querySnapshot.forEach((doc) => {
            questionData.push(doc.data());
            setQuestionsData(questionData);
            setQuestionLoading(false)
          });
        }
      })
    }
    GetQuestionsData()
  }, [catID])

  useEffect(() => {
    const GetAnswers = async () => {
        setAnswerLoading(true)
        const q = query(collection(db, "Answers"), where("QuestionID", "==", questionID));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          if(querySnapshot.empty){
            setAnswersData("NO DATA");
            setAnswerLoading(false)
          }else{
            const answerData = []
            querySnapshot.forEach((doc) => {
              answerData.push(doc.data());
              setAnswersData(answerData);
              setAnswerLoading(false)
            });
          }
        })
    }
    GetAnswers()
  }, [questionID])

  // useEffect(() => {
  //   const GetSearchedResults = async () => {
  //     const Question = searchedKeyword.split(" ");
  //     const q = query(collection(db, "Questions"), where("Keywords", "array-contains-any", Question));
  //     const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //       if(querySnapshot.empty){
  //         setSearchedQuestionsData("NO DATA");
  //         // console.log("NO DATA");
  //       }else{
  //         let questionData = []
  //         querySnapshot.forEach((doc) => {
  //           questionData.push(doc.data());
  //           setSearchedQuestionsData(questionData);
  //           // console.log(questionData);
  //         });
  //       }
  //     })
  //     const Answer = searchedKeyword.split(" ");
  //     const v = query(collection(db, "Answers"), where("Keywords", "array-contains-any", Answer));
  //     const unsubscribed = onSnapshot(v, (querySnapshot) => {
  //       if(querySnapshot.empty){
  //         setSearchedAnswersData("NO DATA");
  //         // console.log("NO DATA");
  //       }else{
  //         let answerData = []
  //         querySnapshot.forEach((doc) => {
  //           answerData.push(doc.data());
  //           setSearchedAnswersData(answerData);
  //           // console.log(answerData);
  //         });
  //       }
  //     })
  //   }
  //   GetSearchedResults()
  // }, [searchedKeyword])

  const GetSearchedResults = async (e) => {
    const Question = e.split(" ");
    const q = query(collection(db, "Questions"), where("Keywords", "array-contains-any", Question));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if(querySnapshot.empty){
        setSearchedQuestionsData("NO DATA");
        // console.log("NO DATA");
      }else{
        let questionData = []
        querySnapshot.forEach((doc) => {
          questionData.push(doc.data());
          setSearchedQuestionsData(questionData);
          // console.log(questionData);
        });
      }
    })
    const Answer = e.split(" ");
    const v = query(collection(db, "Answers"), where("Keywords", "array-contains-any", Answer));
    const unsubscribed = onSnapshot(v, (querySnapshot) => {
      if(querySnapshot.empty){
        setSearchedAnswersData("NO DATA");
        // console.log("NO DATA");
      }else{
        let answerData = []
        querySnapshot.forEach((doc) => {
          answerData.push(doc.data());
          setSearchedAnswersData(answerData);
          // console.log(answerData);
        });
      }
    })
  }

  const GetIPAddress = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
  }
  GetIPAddress()

  // useEffect(() => {
  //   alert(`User cookie is ${JSON.stringify(cookies["user"])}`);
  // }, [])

  // useEffect(() => {
  //   console.log(searchedAnswersData)
  // }, [searchedAnswersData])

  // useEffect(() => {
  //   console.log(searchedQuestionsData)
  // }, [searchedQuestionsData])

  // useEffect(() => {
  //   console.log(searchedKeyword)
  // }, [searchedKeyword])

  return (
    <>
      <BrowserRouter>
        <CustomSwitch>
          <Route index element={<Home SendUserData={(value) => setUserData(value)} Category={categories} UserData={userData} ChangeCategory={(value) => setCategory(value)} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Login" element={<Login Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Register" element={<Register Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Contact" element={<Contact Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Profile" element={<Profile ChangeUserData={(value) => setUserData(value)} Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Profile/UpdateUsername" element={<UpdateUsername Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Profile/UpdateEMail" element={<UpdateEMail Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Profile/UpdatePassword" element={<UpdatePassword Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Profile/UpdateProfilePicture" element={<UpdateProfilePicture Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Profile/DeleteAccount" element={<DeleteAccount Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Category/:ID/:Name" element={<Category SendCategoryID={(value) => setCatID(value)} Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Create" element={<Create Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Ask/:ID/:Name" element={<Ask Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Reply/:CatID/:QuestionID/:Question" element={<Reply Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Questions/:CatID/:QuestionID/:Question" element={<Questions SendCategoryID={(value) => setCatID(value)} SendQuestionID={(value) => setQuestionID(value)} Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route path="/Results/:SearchValue" element={<Search SendCategoryID={(value) => setCatID(value)} SendQuestionID={(value) => setQuestionID(value)} Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
          <Route exact path="*" element={<PageNotFound Category={categories} UserData={userData} IPAddress={ip} CategoryID={category?.id} CategorySubject={category?.Subject} CategoryUserName={category?.UserName} DataOfQuestion={questionsData} DataOfAnswer={answersData} SendCategoryLoading={categoryLoading} SendQuestionLoading={questionLoading} SendAnswerLoading={answerLoading} SendSearchText={(value) => setSearchingText(value)} SearchText={searchingText} GetSearchKeyword={(value) => GetSearchedResults(value)} SearchedQuestionsData={searchedQuestionsData} SearchedAnswersData={searchedAnswersData}/>}/>
        </CustomSwitch>
      </BrowserRouter>
    </>
  );
}

export default App;
