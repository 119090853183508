import React, { useContext,useEffect,useState } from 'react';
import { doc, updateDoc, setDoc, serverTimestamp, increment } from "firebase/firestore";
import { storage, db } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { AuthContext } from '../context/AuthContext';
import { useNavigate,useParams } from 'react-router-dom';
import { v4 as uuid } from "uuid";
import swal from '@sweetalert/with-react';
import LoadingBar from 'react-top-loading-bar';
import SearchBar from "../components/SearchBar";

const Reply = (props) => {

    const navigate = useNavigate()
    const { currentUser } = useContext(AuthContext);
    const { QuestionID } = useParams();
    const { Question } = useParams();
    const [text, setText] = useState("");
    const [pfpFile, setPFPFile] = useState(null);
    const [err, setErr] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [progresspercent, setProgressPercent] = useState();
    const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null);
    const [searchText,setSearchText] = useState(props.SearchText);
    const [searchKeyword, setSearchKeyword] = useState("");

    if (!currentUser) {
      navigate("/")
    }

    document.title = "Drake Forum • Answer The Question"

    useEffect(() => {
        const interval = setInterval(() => {
        var current = new Date();
        var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
        var CurrentDate = current.toLocaleDateString();
        setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const HandleSubmit = async(e) => {
        e.preventDefault();

        setLoading(true)

        const Answer = e.target[0].value;
        const AnswerKeywords = Answer.replaceAll("?","").replaceAll("-","").replaceAll("_","").replaceAll("<","").replaceAll(">","").replaceAll(".","").replaceAll(",","").replaceAll(":","").replaceAll("'","").replaceAll("\"","").replaceAll("[","").replaceAll("]","").replaceAll("{","").replaceAll("}","").replaceAll("(","").replaceAll(")","").replaceAll(";","");
        const UniqueID = uuid();
        const JoinUsernameUniqueID = currentUser.displayName + '_' + UniqueID;

        if (pfpFile !== null) {
            try {
                const storageRef = ref(storage, "Answer's_Screenshot/" + JoinUsernameUniqueID);
                const uploadTask = uploadBytesResumable(storageRef, pfpFile);
                uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const roundedprogress = Math.round(progress);
                    setProgressPercent(roundedprogress);
                },
                (error) => {
                    setErr(true);
                    setLoading(false);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        await setDoc(doc(db, "Answers", UniqueID), {
                            id: UniqueID,
                            Answer: Answer,
                            Question: Question.replaceAll("-"," "),
                            Time: combinedCurrentDateTime,
                            UserName: currentUser.displayName,
                            IP: props.IPAddress,
                            date: serverTimestamp(),
                            QuestionID: QuestionID,
                            AnswerScreenShot: downloadURL,
                            Keywords: AnswerKeywords.toLowerCase().split(" "),
                        });
                        await updateDoc(doc(db, "Users", currentUser.uid), {
                            NoOfQuestionAnswered: increment(1),
                        });
                        e.target[0].value = ""
                        setPFPFile(null)
                        swal(`Submitted Answer : ${Answer}`)
                    });

                });

            } catch (err) {
                setErr(true);
                setErrorCode(err.code);
                setErrorMessage(err.message);
                setLoading(false);
            }
        } else {
            await setDoc(doc(db, "Answers", UniqueID), {
                id: UniqueID,
                Answer: Answer,
                Question: Question.replaceAll("-"," "),
                Time: combinedCurrentDateTime,
                UserName: currentUser.displayName,
                IP: props.IPAddress,
                date: serverTimestamp(),
                QuestionID: QuestionID,
                AnswerScreenShot: "",
                Keywords: AnswerKeywords.toLowerCase().split(" "),
            });
            await updateDoc(doc(db, "Users", currentUser.uid), {
                NoOfQuestionAnswered: increment(1),
            });
            e.target[0].value = ""
            setPFPFile(null)
            setText("")
            swal(`Submitted Answer : ${Answer}`)
        }

    }

    const handleImageChange = (e) => {
        const validExtensions = ["image/jpg", "image/png", "image/jpeg"]
        if (!validExtensions.includes(e.target.files[0].type)) {
            swal("File Format Not Supported")
            setText("");
            setPFPFile(null)
            return false
        } else {
            if (e.target.files[0].size > 10 * 1024 * 1024) {
                swal("File Size Should Be Less Than 10 MB")
                setText("");
                setPFPFile(null)
                return false
            } else {
                setText(e.target.files[0].name)
                setPFPFile(e.target.files[0])
            }
        }
    }

    
    useEffect(() => {
        props.SendSearchText(searchText)
    }, [searchText])

    
    // useEffect(() => {
    //     props.GetSearchKeyword(searchKeyword);
    // }, [searchKeyword])
    
    return (
        <div className='HomeBody'>
            {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
            <div className="app">
                <div className="header">
                    <SearchBar SearchText={props.SearchText} SearchBarText={(value) => setSearchText(value)} GetSearchKeyword={(value) => props.GetSearchKeyword(value)}/>
                    <div className="header-profile">
                        {currentUser
                            ? 
                                <img className="profile-img cursor-pointer" src={currentUser.photoURL} alt="" onClick={() => navigate("/Profile")}/>
                            :
                                <div className="header-menu cursor-pointer text-slate-300" onClick={() => navigate("/Login")}>
                                    Login
                                </div>
                        }
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="main-header">
                            <div className="header-menu">
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/")}>Home</a>
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/Contact")}>Contact</a>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="content-wrapper-header">
                                <div className="content-wrapper-context">
                                    <h3 className="img-content">Answer The Question : {Question.replaceAll("-"," ")}</h3>
                                    <div className="content-text capitalize">Please answer the query properly as it will help other users in solving the problem easily</div>
                                </div>
                            </div>
                            <div className="content-section">
                                <form onSubmit={HandleSubmit} className="ReplyForm">
                                    <div className="ReplyCategoryName">
                                        <textarea id="ReplyArea" placeholder="Please Write Your Answer" required></textarea>
                                    </div>
                                    <div className="login__box_pfp_Submit">
                                        <input type="file" accept="image/png, image/jpeg" placeholder="Image" className="login__input" id="File" style={{ display: "none" }} onChange={(e) => handleImageChange(e)} />
                                        <label htmlFor="File" className="flex flex-row space-x-4">
                                            <i className="bx bxs-file-image login__icon cursor-pointer"></i>
                                            <h1 className="login__input_submit cursor-pointer text-white">{text === "" ? "Add Screenshot Of The Answer (OPTIONAL)" : text}</h1>
                                        </label>
                                    </div>
                                    <button className="content-button">SUBMIT</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
        </div>
    )
}

export default Reply