import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { updateProfile, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import SearchBar from "../components/SearchBar";
import swal from "@sweetalert/with-react";

const UpdateUsername = (props) => {
    document.title = "Drake Forum • Update Username"
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [searchText,setSearchText] = useState(props.SearchText);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [err, setErr] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const HandleSubmit = async (e) => {
        // setLoading(true)
        e.preventDefault();
        const NewUsername = e.target[0].value;
        updateProfile(currentUser, {
            displayName: NewUsername
        }).then(async () => {
        updateDoc(doc(db, "Users", currentUser.uid), {
            displayName: NewUsername
        });
        // setLoading(false);
        // setShowAlert(true);
        swal("USERNAME UPDATED TO : " + NewUsername);
        e.target[0].value = "";
        // setShowAlert(false);
        await sleep(2000);
        }).catch((error) => {
            setErr(true);
            // setShowAlert(false);
            // setLoading(false);
            setErrorCode(error.code);
            setErrorMessage(error.message);
            if (error.code === "auth/requires-recent-login") {
                swal("Authorization Credentials Expired, Signing Out. Please Sign In Again")
                signOut(auth)
                // window.location.reload()
            }
        });
    }

    if (!currentUser) {
        navigate("/")
    }
  
    useEffect(() => {
        props.SendSearchText(searchText)
    }, [searchText])

    
    // useEffect(() => {
    //     props.GetSearchKeyword(searchKeyword);
    // }, [searchKeyword])
    
    return (
        <div className='HomeBody'>
            <div className="app">
                <div className="header">
                    <div className="header-menu top-menu">
                        <a className="menu-link is-active" href="/">Home</a>
                        <a className="menu-link notify" href="/">Contact</a>
                        <a className="menu-link" href="/">Login</a>
                        <a className="menu-link notify" href="/">Register</a>

                        <div className="header-menu">
                            <a className="menu-link" href="/">Login</a>
                        </div>

                    </div>
                    <SearchBar SearchText={props.SearchText} SearchBarText={(value) => setSearchText(value)} GetSearchKeyword={(value) => props.GetSearchKeyword(value)}/>
                    <div className="header-profile">
                        {currentUser
                            ? 
                                <img className="profile-img cursor-pointer" src={currentUser.photoURL} alt="" onClick={() => navigate("/Profile")}/>
                            :
                                <div className="header-menu cursor-pointer text-slate-300" onClick={() => navigate("/Login")}>
                                    Login
                                </div>
                        }
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="main-header">
                            <div className="header-menu">
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/")}>Home</a>
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/Contact")}>Contact</a>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="content-wrapper-header">
                                <div className="content-wrapper-context">
                                    <h3 className="img-content">UPDATE USERNAME</h3>
                                    <div className="content-text capitalize">Please choose your username wisely</div>
                                </div>
                            </div>
                            <div className="content-section">
                                <form onSubmit={HandleSubmit} className="CategoryForm">
                                    <div className="CategoryName">
                                        <input type="text" placeholder="NEW USERNAME" required/>
                                    </div>
                                    <button className="content-button">SUBMIT</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
        </div>
    )
}

export default UpdateUsername
