import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';

const SearchBar = (props) => {
  const [value, setValue] = useState(props.SearchText);
  const navigate = useNavigate();

  const HandleChange = (e) => {
    setValue(e.target.value);
    props.SearchBarText(e.target.value);
  }

  const GetDataOnKeyPress = (event) => {
    if(event.key === 'Enter'){
      props.GetSearchKeyword(value);
      if(value !== ''){
        navigate(`/Results/${value.replaceAll(" ","-")}`);
      }
    }
  }

  const GetDataOnClick = () => {
    props.GetSearchKeyword(value);
    if(value !== ''){
      navigate(`/Results/${value.replaceAll(" ","-")}`);
    }
  }

  return (
    <>
        <div className="search-bar" style={{justifyContent: 'center',alignItems: 'center',textAlign: 'center'}}>
          <input type="text" placeholder="Search Forum" onChange={HandleChange} onKeyDown={(e) => GetDataOnKeyPress(e)} value={value} id="Search_Bar"/>
          <i onClick={GetDataOnClick} className="cursor-pointer shadow-lg shadow-violet-400/50.5 flex h-full items-center fi fi-rr-search ml-2 mr-2"></i>
        </div>
    </>
  )
}

export default SearchBar